body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

#footer{
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
    padding-top: 5%;
    border-top: 1px solid rgb(127, 127, 127);
}

#footer #vortexLabs{
    width:90%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
#vortexLabs img{
    width:30%;
}

#footer p{
    color:rgb(172, 172, 172);
    text-align: justify;
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    width:70% !important;
    margin-top: 3%;
}
#footer-info{
    width:70%;
    display:flex;
    flex-direction: row;
}

#footer-info #contact{
    display:flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
}

#contact p{
    font-size: 0.9vw;
}

#contact p:nth-of-type(1)
{
    font-family: 'Poppins', sans-serif;
    font-size: 1.5vw;
    text-decoration: underline;
}

#quick-links{
    width:70%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#quick-links p{
    font-family: 'Poppins', sans-serif;
    font-size: 1.3vw;
    text-decoration: underline;
    cursor:pointer;
}

#quick-links p:hover{
    color:white;
}

@media only screen and (min-width:0px) and  (max-width:700px){
    #vortexLabs img{
        width:40% !important;
    }

    #footer p{
        font-size: 0.8vh !important;
    }

    #contact p{
        font-size: 0.6vh;
    }

    #contact p:nth-of-type(1){
        font-size: 0.8vh;
    }
    #quick-links p{
        font-size: 0.6vh;
    }

    body{
        overflow-x: hidden;
    }
}

@media only screen and (min-width:700px) and  (max-width:1300px)
{
    #vortexLabs img{
        width:40%;
    }

    #footer p{
        font-size: 1vh;
    }

    #contact p{
        font-size: 0.8vh;
    }

    #contact p:nth-of-type(1){
        font-size: 1.5vh;
    }
    #quick-links p{
        width:100% !important;
        font-size: 1vh !important;
    }

    body{
        overflow-x: hidden;
    }
}

@media only screen and (min-width:1000px) and  (max-height:602px) {
    #vortexLabs img{
        width:30%;
    }

    #footer p{
        font-size: 1vw;
    }

    #contact p{
        font-size: 1vw;
    }

    #contact p:nth-of-type(1){
        font-size: 1.5vw;
    }
    #quick-links p{
        font-size: 1.4vw !important;
    }

    body{
        overflow-x: hidden;
    }
}