/* components/ScrollUpButton.css */
.scroll-up-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #00D8E3; /* Blue background color */
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.scroll-up-button:hover {
    background-color: #069aa1; /* Darker blue on hover */
}
