@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
}
.textAnimationDiv{
    margin: 2vw;
    width:70%;
}
.main-content {
    font-size: 2.2vw !important;
}

span {
    color: #00D8E3;
}

.gridDiv {
    position: relative;
    z-index: 5;
    --grid-width: 20;
    --grid-height: 20;
}

.type::after {
    content: '}';
    animation: cursor 1.1s infinite step-start;
  }
   
  @keyframes cursor {
    50% {
      opacity: 0;
    }
  }

  .type1::after {
    content: ' }';
    animation: cursor 1.1s infinite step-start;
  }
   
  @keyframes cursor {
    50% {
      opacity: 0;
    }
  }
#logo-res{
    width:100vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


#logo-res img{
    width:50%;
}

.mainDiv #logo{
    display:none;
}

.buttonsDiv {
    position: relative;
    background: rgb(1,83,87);
    background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 15357%);
    align-items: center;
    width: 40%;
    border-radius: 25px;
    cursor: pointer;
    align-self: start;
    overflow: hidden; 
    transition: all 0.3s ease-out;
    padding-left: 6%;
}

/* .buttonsDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    transition: all 0.5s ease-in-out;
}

.buttonsDiv:hover::before {
    left: 100%;
}

.buttonsDiv p, .buttonsDiv img {
    position: relative;
    z-index: 2; /* Ensure text and image are above the sliding effect */
/* } */

/* .buttonsDiv:hover {
    transform: scale(1.05);
} */ 


#build-button{
    padding: 1%;
    min-width:24rem;
    height:5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgb(1,83,87);
    background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
    align-items: center;
    border-radius: 15px;
}

@keyframes buildAnimation {
    from{
        background: rgb(1,83,87);
        background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
    }
    to{
        background: rgb(1, 48, 50);
        background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
    }
}

.buttonsDiv img{
    width:25%;
    height:90%;
}

.buttonsDiv p{
    font-size: 1vw !important;
    font-weight: 600;
    text-align: center !important;
}

#build-button:hover{
    cursor:pointer;
    animation-name: buildAnimation;
}

#exploreDiv{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes pulse {
    from{
        transform: scale(1);
    }
    to{
        transform: scale(0.9);
    }
    
}


#explore-image{
    width:120px;
    height:120px;
    background-size: cover;
    background-image: url("../assets//explore.png");
    cursor:pointer;

    animation-name: pulse;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: pointer;
}

#exploreDiv p{
    text-align: center !important;
    padding-left: 1%;
    margin: 0%;
    cursor:pointer;
}

/* Media queries in descending order */
@media (max-width: 2560px) {
    .buttonsDiv::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transform: skewX(-20deg);
        transition: all 0.5s ease-in-out;
    }
    
    .buttonsDiv:hover::before {
        left: 100%;
    }
    
    .buttonsDiv p, .buttonsDiv img {
        position: relative;
        z-index: 2; /* Ensure text and image are above the sliding effect */
    }
    
    .buttonsDiv:hover {
        transform: scale(1.05);
    }

    .main-content {
        font-size: calc(2em + 2vw);
    }
    body{
        overflow-x: hidden;
    }
}

@media (max-width: 1920px) {

    .buttonsDiv::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transform: skewX(-20deg);
        transition: all 0.5s ease-in-out;
    }
    
    .buttonsDiv:hover::before {
        left: 100%;
    }
    
    .buttonsDiv p, .buttonsDiv img {
        position: relative;
        z-index: 2; /* Ensure text and image are above the sliding effect */
    }
    
    .buttonsDiv:hover {
        transform: scale(1.05);
    }

    .main-content {
        font-size: calc(2em + 1.5vw);
    }
    body{
        overflow-x: hidden;
    }
}

@media (max-width: 1600px) {

    .buttonsDiv::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transform: skewX(-20deg);
        transition: all 0.5s ease-in-out;
    }
    
    .buttonsDiv:hover::before {
        left: 100%;
    }
    
    .buttonsDiv p, .buttonsDiv img {
        position: relative;
        z-index: 2; /* Ensure text and image are above the sliding effect */
    }
    
    .buttonsDiv:hover {
        transform: scale(1.05);
    }


    .trianStyle {
        display: none; /* Hides triangle image on small screens */
    }
    body{
        overflow-x: hidden;
    }
}

@media (max-width: 1400px) {

    .buttonsDiv::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transform: skewX(-20deg);
        transition: all 0.5s ease-in-out;
    }
    
    .buttonsDiv:hover::before {
        left: 100%;
    }
    
    .buttonsDiv p, .buttonsDiv img {
        position: relative;
        z-index: 2; /* Ensure text and image are above the sliding effect */
    }
    
    .buttonsDiv:hover {
        transform: scale(1.05);
    }


    .main-content {
        font-size: calc(1em + 2vw) !important;
    }
    body{
        overflow-x: hidden;
    }
    
}

@media (max-width: 1200px) {

    .buttonsDiv::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transform: skewX(-20deg);
        transition: all 0.5s ease-in-out;
    }
    
    .buttonsDiv:hover::before {
        left: 100%;
    }
    
    .buttonsDiv p, .buttonsDiv img {
        position: relative;
        z-index: 2; /* Ensure text and image are above the sliding effect */
    }
    
    .buttonsDiv:hover {
        transform: scale(1.05);
    }


    .gridDiv {
        display: none;
    }

    body{
        overflow-x: hidden;
    }
    
}
@media (max-width: 1024px){
    .mainDiv #logo{
        display:block;
        width:50%;
    }
    .buttonsDiv p{
        font-size: 2vh !important;
    }
    .main-content {
        font-size: calc(1em +1vw) !important;
    } 
}
@media (max-width: 1024px) and (min-height: 900px) {
   
    .mainDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
    }
   
    .buttonsDiv p{
        font-size: 1.8vh !important;
    }
     
    .mainDiv{
        height: 50vh !important;
    } 
    .main-content {
        font-size: calc(1em +3vw) !important;
    } 
}
@media (max-width: 820px ) {
    .buttonsDiv {
        flex-direction: column;
        width:100%;
    }
    
    .main-content {
        font-size: calc(1em + 2vw) !important;
    }

    body{
        overflow-x: hidden;
    }

    #build-button{
        padding: 2%;
        width: 10rem;
        width:10vw;
        height:5vh;
        margin-left: 0;
    }

    .buttonsDiv p{
        font-size: 1.8vh !important;
    }

    .buttonsDiv{
        width:50% !important;
        justify-content: center;
        margin-left: 25% !important;
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        background-color: #00D8E3;
    }

    .textAnimationDiv{
        margin: 0vw;
        width:50%;
        text-align: center;
        margin-left: 0% !important;
        margin-top: 0%;
        margin-bottom: 10%;
    }
    .mainDiv{
        height: 50vh !important;
    }
}

@media (max-width: 540px) {
    .buttonsDiv {
        flex-direction: column; /* Stack items vertically */
        width: 100%; /* Make the buttons div take full width */
        
    }
    .gridDiv {
        --grid-width: 5;
        --grid-height: 5;
        display: none;
    }
    .main-content {
        font-size: calc(1.5em + 0.5vw) !important;
        text-align: center;
        width:90%;
        padding-top: 0% !important;
    }


    .mainDiv #logo{
        display:block;
        width:80%;
    }

    body{
        overflow-x: hidden;
    }

    #build-button{
        padding: 2%;
        width: 10px !important;
        margin-left: 0;
        background-color: #00D8E3;
    }

    .buttonsDiv p{
        font-size: 1.8vh !important;
    }

    .buttonsDiv{
        width:70% !important;
        justify-content: center;
        margin-left: 15% !important;
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        background-color: #00D8E3;
        align-self:center;
    }


    .textAnimationDiv{
        margin: 0vw;
        width:100%;
        text-align: center;
        margin-left: 20% !important;
        margin-top: 0%;
        margin-bottom: 10%;
    }


}
