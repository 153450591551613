html, body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important; /* Prevent horizontal overflow */
}

#contact-container {
    width: 100vw;
    height:120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden !important;
    background: rgb(1,83,87);
    background: linear-gradient(93deg, rgb(0, 11, 11) 0%, rgb(0, 38, 40) 88%, rgba(0, 66, 69, 0.954) 015357%); 
}

#contact-container img{
    width:25%;
    margin-top: 0%;
    cursor:pointer;

}

#contact-container p{
    font-size:1vw !important;
    width:60% !important;
    margin-top: -1%;
}

#contact-container input{
    padding:1%;
    width:20%;
    font-size: 0.8vw;
    margin-top: 0.8%;
    border-radius: 25px;
    padding-left: 2%;
    border:none;
    background: rgb(1,83,87);
    background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
    color:white;
}

#contact-container textarea{
    padding:1%;
    width:20%;
    height: 20vh !important;
    font-size: 0.8vw;
    margin-top: 1%;
    border-radius: 25px;
    padding-left: 2%;
    border:none;
    background: rgb(1,83,87);
    background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
    color:white;
}

#contact-container button{
    padding:1%;
    width:23%;
    font-size: 0.8vw;
    margin-top: 1%;
    border-radius: 25px;
    text-align: center;
    border:none;
    background: rgb(1,83,87);
    background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
    color:white;
    cursor:pointer;
    margin-bottom: 5%;
}

#contact-container input::placeholder{
    opacity: 1;
    color:white;
}

#contact-container textarea::placeholder{
    opacity: 1;
    color:white;
}



@media only screen and (min-width: 0px) and (max-width: 1000px) {
    #contact-container img{
        width:95%;
        margin-top: -10%;
    }

    #contact-container p{
        font-size:1.2vh !important;
        width:85% !important;
        margin-top: 2%;
    }

    #contact-container input{
        padding:2.5%;
        width:65%;
        font-size: 1vh;
        margin-top: 2%;
        border-radius: 15px;
        padding-left: 5%;
        border:none;
        background: rgb(1,83,87);
        background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
        color:white;
    }

    #contact-container textarea{
        padding:2.5%;
        width:67%;
        height: 20vh;
        font-size: 1vh;
        margin-top: 2%;
        border-radius: 15px;
        padding-left: 2%;
        border:none;
        background: rgb(1,83,87);
        background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
        color:white;
    }

    #contact-container button{
        padding:2.5%;
        width:75%;
        font-size: 1vh;
        margin-top: 2%;
        border-radius: 25px;
        padding-left: 2%;
        border:none;
        background: rgb(1,83,87);
        background: linear-gradient(93deg, rgba(1,83,87,1) 0%, rgba(92,225,230,1) 88%, rgba(147,147,147,0.9542017490589986) 015357%);
        color:white;
        cursor:pointer;
        margin-bottom: 5%;
    }
}
