/* WaterDropGrid.css */

body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.waterdrop-grid {
    display: grid;
    place-content: center;
    padding: 3rem 2rem;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
}

.dot-container {
    display: grid;
    grid-template-columns: repeat(25, 4%);
    width: 100%;
    max-width: 100%;
}

.dot-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.2s;
}

.dot-point {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #c0d9da;
    opacity: 0.5;
    transition: background-color 0.2s;
}

.dot-wrapper:hover .dot-point {
    background-color: #00D8E3;
}

@media (max-width: 768px) {
    .dot-container {
        grid-template-columns: repeat(15, 6%);
    }

    .dot-point {
        height: 6px;
        width: 6px;
    }

    .dot-wrapper {
        padding: 6px;
    }
}

@media (max-width: 480px) {
    .dot-container {
        grid-template-columns: repeat(10, 10%);
    }

    .dot-point {
        height: 4px;
        width: 4px;
    }

    .dot-wrapper {
        padding: 4px;
    }
}
