@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

h1 {
    margin: 0;
    padding-top: 20px;
    text-align: center;
}

.mainHeading {
    text-align: center;
    padding-top: 20px;
}

.mainContent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 5%;
}

.aboutVideo {
    width: 30%;
    height: auto;
    max-width: 100%;
    margin: 10px;
}

.mainContent #mp {
    margin: 10px;
    width: 50% !important;
    text-align: justify;
    box-sizing: border-box;
    font-size: 1.2vw !important;
}

@media (max-width: 1024px) {
    .mainDivCSS {
        min-height: 100vh; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
    }
    .mainContent {
        display: flex;
        flex-direction: column-reverse;
        font-size: calc(1em + 1vw);
        height: auto;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .aboutVideo {
        width: 100%;
        max-width: 400px;
        margin: 10px 0;
    }
    .mainContent #mp {
        width:90% !important;
        font-size: 2vh !important;
        margin: 10px 0;
        text-align: justify;
    }
}
