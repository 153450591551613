:root {
    --clr-neutral-900: hsl(207, 19%, 9%);
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: hsl(178, 89%, 42%);
  }
  
  .cards-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2vw;
    padding: 0 1rem; /* Padding for space on the left and right */
  }
  
  .card {
    color: var(--clr-neutral-100);
    background-size: cover;
    background-position: center;
    padding: 0;
    width: 100%;
    max-width: 22vw; /* Ensures all cards are the same width */
    height: 26vw; /* Ensures all cards are the same height */
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .card-content {
    --padding: 1rem;
    padding: var(--padding);
    background: linear-gradient(
      hsl(0 0% 0% / 0),
      hsl(20 0% 0% / 0.3) 20%,
      hsl(0 0% 0% / 1)
    );
    border-radius: 0.5rem;
    width: 100%;
  }
  
  .card-title {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 1.4vw !important; /* Adjusted font size for large screens */
  }
  
  .card-title::after {
    content: "";
    position: absolute;
    height: 4px;
    width: calc(100% + var(--padding));
    left: calc(var(--padding) * -1);
    bottom: -2px;
    background: var(--clr-accent-400);
    transform-origin: left;
    transition: transform 500ms ease;
    transform: scaleX(0);
  }
  
  .card:hover .card-title::after,
  .card:focus-within .card-title::after {
    transform: scaleX(1);
  }
  
  .card-body {
    margin-bottom: 1rem;
    font-size: 1.2rem !important;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    border: none;
    color: var(--clr-neutral-900);
    background-color: var(--clr-accent-400);
    padding: 0.5em 1.25em;
    border-radius: 0.25rem;
    align-self: start; /* Ensure button aligns with the left of the card */
  }
  
  .button:hover,
  .button:focus {
    background-color: var(--clr-neutral-100);
  }

  /* Additional styles for expandable content */
.card.expanded {
  max-height: none; /* Allow card to expand fully */
  transition: max-height 0.3s ease;
}

.expanded-content {
  margin-top: 1rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Smooth transition for expanding content */
.card.expanded .expanded-content {
  opacity: 1;
}

.card .expanded-content {
  display: none;
}

.card.expanded .expanded-content {
  display: block;
}

/* Ensure button aligns with expanded content */
.card.expanded .button {
  margin-top: 1rem;
}

.card-content p{
  font-size: 1vw !important;
  width:80%;
}

.card-content h2{
  font-size: 2vh !important;
}

  
  /* Responsive wrapping for screens below HD (less than 1280px) */
  @media (max-width: 1280px) {

    .card-content p{
      font-size: 1.3vh !important;
      width:80%;
    }

    .card-content h2{
      font-size: 1.5vh !important;
    }

    .cards-container {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .card {
      max-width: 45vw; /* Adjusted width for medium screens */
      height: auto; /* Let height adjust with content */
    }
  }
  
  /* Responsive stacking for small screens (below 768px) */
  @media (max-width: 768px) {

    .card-content p{
      font-size: 1vh !important;
      width:80%;
    }

    .card-content h2{
      font-size: 1.5vh !important;
    }


    .cards-container {
      flex-direction: column;
      align-items: center;
      gap: 2rem; /* Increase gap between stacked cards */
    }
  
    .card {
      max-width: 90vw; /* Increase card width on small screens */
      height: auto; /* Allow height to adjust based on content */
      transform: none;
      opacity: 1;
    }
  
    .card-content {
      padding: 1.5rem; /* Adjust padding for better readability */
    }
  
    .card-title {
      font-size: 5vw !important; /* Further reduced font size for small screens */
    }
  }
  
  /* Swipe functionality for very small screens (below 480px) */
  @media (max-width: 480px) {

    .card-content p{
      font-size: 1.3vh !important;
      width:80%;
    }
    .cards-container {
      flex-direction: row;
      overflow-x: auto;
      scroll-snap-type: x mandatory; /* Enable snap scrolling */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
      justify-content: center; /* Align cards to the start */
      padding: 0 1rem; /* Ensure cards start within view */
    }
  
    .card {
      flex: 0 0 80%; /* Make each card take up 80% of the screen width */
      scroll-snap-align: start; /* Snap each card to the start */
      margin-right: 1rem; /* Add spacing between the cards */
    }
  
    .card-title {
      font-size: 6vw !important; /* Further reduced font size for very small screens */
    }
  }
  