@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.mainDivNav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
    margin-top: 2%;
    background-color: black;
    height: 15%;
    width: 70%;
    margin-left: 15%;
    z-index: 1000;
    top: 0;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
    overflow-x: hidden; /* Prevent horizontal overflow */
    overflow: hidden;
    border-bottom: 1px solid #00D8E3;
    padding-bottom: 2%;
}



.logo {
    width:30%;
}

.navLinks {
    list-style-type: none;
    display: flex;
    gap: 15px;
    margin: 0;
    padding: 0;
}

.navLinks li {
    cursor: pointer;
    color: white;
    padding: 1vw;
    font-size: 1.2vw;
}

.navLinks li:hover {
    color: #00D8E3;
}

.chatButton {
    background-color: #00D8E3;
    border-radius: 1vh;
    color: white;
    width:10%;
    border: none;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    cursor: pointer;
    font-size: inherit;
    font-weight: bold;
}

ul {
    font-size: calc(1em + 1vw);
}

.menuButton {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 40px;
    cursor: pointer;
}

.closeButton {
    display: none;
}
@media (max-width: 1024px) and (min-height: 650px) {
    .logo {
        width:40%;
    }
    body{
        overflow-x: hidden;
    }
}

@media (max-width: 1024px) {
    .mainDivNav .chatButton{
        display:none;
    }
    
    .navLinks {
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        padding-top: 60px;
        padding-left: 20px;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        z-index: 1001;
        box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
    }

    .navLinks.open {
        transform: translateX(0);
    }

    .menuButton {
        display: block;
    }

    .closeButton {
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    .mainDivNav {
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
        display: none;
    }

    body{
        overflow-x: hidden;
    }
}
