@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
    font-family: 'Poppins', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

h1 {
    text-align: center;
    font-size: calc(2.5em + 1vw) !important;
    margin: 0px 20px;
}

#service-heading{
    padding-top: 10%;
}
span {
    color:#00D8E3;
}

@media (max-width: 1920px)
{
    h1 {
        font-size: calc(2em + 2vw);
    }
}
@media (max-width: 1024px)
{
    h1 {
        font-size: calc(2em + 0.5vw) !important;
    }
}

@media (max-width: 550px)
{
    h1 {
        font-size: calc(1em + 3vw) !important;
    }
}

